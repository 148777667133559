.hero-wrapper{
    @apply relative h-700 max-h-700 text-white overflow-hidden;
}

.hero-bg-filter{
    @apply absolute top-0 left-0 w-full h-700 max-h-700 z-20;
    background-color: rgba(25,25,25,0.35);
}

.hero-text{
    @apply absolute top-1/2 left-1/2 z-20 transform -translate-y-1/2 -translate-x-1/2 flex flex-col justify-center items-center space-y-12;
}

.hero-main{
    @apply flex flex-col justify-center items-center;
}

.hero-title{
    @apply text-6xl lg:text-12xl text-center;
}


.hero-contacts{
    @apply flex flex-col justify-center items-center space-y-8 text-center;
}


.hero-contacts-question{
    @apply text-3xl lg:text-4xl;
}

.hero-contacts-button{
    @apply py-3 px-6 bg-red-700 shadow-lg;
}

.hero-carousel-slide-container{
    @apply h-700 max-h-700;
}

.hero-carousel-slide{
    @apply w-full float-left;
}

.hero-carousel-image{
    @apply absolute top-0 left-0 w-full h-700 max-h-700 object-cover object-center transition-all;
    transition-duration: 2000ms;
}

.arrow-left{
    @apply absolute hidden md:block top-1/2 md:left-10 z-40 transform -translate-y-1/2 -mt-6 text-4xl;
}

.arrow-right{
    @apply absolute hidden md:block top-1/2 md:right-10 z-40 transform -translate-y-1/2 -mt-6 text-4xl;
}
