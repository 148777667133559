.intro-wrapper{
    @apply py-24 px-5 md:px-20;
}

.intro-container{
    @apply  flex flex-col justify-center items-center space-y-16;
}

.intro-title-container{
    @apply flex flex-col justify-start items-center space-y-6;
}

.intro-title-big{
    @apply text-red-700 text-center md:text-right text-6xl md:text-13xl;
}

.intro-title-small{
    @apply flex md:flex-col justify-center items-center md:items-start text-center md:text-left md:text-9xl;
}

.intro-title-small-years{
    @apply pr-2 md:pr-0 text-4xl md:text-sm;
}

.intro-title-small-experience{
    @apply text-4xl md:text-4xl;
}

.intro-block-container{
    @apply grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-0 text-center 2xl:px-40;
}

.intro-block{
    @apply group py-4 md:py-0 flex flex-col justify-center items-center space-y-6;
}

.intro-block-icon{
    @apply w-24 h-24 mb-6 transform rotate-45 rounded-lg flex justify-center items-center text-center bg-red-700 ring-8 ring-red-300 text-white;
}

.intro-block-content{
    @apply  space-y-2;
}
