.services-wrapper{
    @apply  md:py-24 px-5 md:px-16 flex justify-center;
}

.services-container{
    @apply w-full 2xl:w-3/4 flex flex-col-reverse xl:flex-row justify-between items-center md:space-x-4;
}

.title-marker{
    @apply text-red-700;
}
