.topnav {
    @apply flex flex-col justify-center items-center md:flex-row md:justify-between py-5 md:px-20 space-y-3 md:space-y-0 bg-red-700 text-xs;
}

.topnav-address {
    @apply flex justify-start items-center;
}

.topnav-contacts {
    @apply flex justify-start items-center space-x-4;
}

.topnav-link{
    @apply text-white hover:text-yellow-500;
}
