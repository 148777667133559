.navbar-wrapper{
    @apply relative flex flex-col justify-center items-center md:flex-row md:justify-between py-4 px-4 lg:px-20 border-b border-red-700;
    border-bottom-width: 0.075rem;
}

.navbar-menu{
    @apply mt-4 md:mt-0 hidden md:block;
}

.navbar-list{
    @apply flex flex-col justify-center items-center md:flex-row md:justify-between space-y-3 md:space-y-0 md:space-x-14;
}

.navbar-link{
    @apply text-sm uppercase hover:text-red-500;
}

.navbar-mobile-toggle{
    @apply hidden top-6 right-3 absolute space-y-1 p-2 border border-gray-600 rounded;
}

.navbar-mobile-burgers{
    @apply w-5 h-1 bg-gray-600 hidden md:block;
    height: 3px;
}
